export default {
	dtLancamentoErros () {
		const errors = []
		// if (!this.$v.obj.params.dtLancamento.$dirty) return errors
		// !this.$v.obj.params.dtLancamento.required && errors.psuh('Data é obrigatória')
		return errors
	},
	pendingOperationTypeIdErros () {
		const errors = []
		if (!this.$v.obj.pendingOperationTypeId.$dirty) return errors
		!this.$v.obj.pendingOperationTypeId.required && errors.push('Tipo é obrigatório')
		return errors
	},
	entityErrors () {
		const errors = []
		if (!this.$v.obj.params.entity.$dirty) return errors
		!this.$v.obj.params.entity.required && errors.push('Estabelecimento é obrigatório')
		return errors
	},
	valueErrors () {
		const errors = []
		if (!this.$v.obj.value.$dirty) return errors
		!this.$v.obj.value.required && errors.push('Valor é obrigatório')
		!this.$v.obj.value.minValue && errors.push('Valor tem que ser positivo')
		return errors
	}
}