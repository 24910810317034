<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">

    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-form>
        <v-card-title class="modal-cadastro-titulo">
          {{ isEdit ? 'Editar pré-caixa' : 'Cadastrar pré-caixa' }}
        </v-card-title>
          

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="mt-2 modal-cadastro-form-col-input" sm="6">
              <autoCompleteVendedor :disabled="isEdit" :error="entityErrors" :type="'v'" :value="obj.params.entity" :entity.sync="obj.params.entity" :typesEntities="['4']" :label="'Estabelecimento'" />
            </v-col>
            
            <v-col cols="12" class="mt-2 modal-cadastro-form-col-input" sm="6">
              <!-- <v-text-field type="number" :error-messages="numberErrors" v-model="item.number" label="Número" @input="$v.item.number.$touch()" @blur="$v.item.number.$touch()" /> -->
              <!-- <v-text-field type="tel" :disabled="true" v-model="input" :label="setLabel(3)" /> -->
               <autoCompleteEntities :entityProps.sync="promotor" :disabled="isEdit || !isCashInAllEntities" :typesEntities="[3]"  :label="'Promotor'"  />
            </v-col>
            <!-- <v-col cols="12" class="modal-cadastro-form-col-input pb-0 pt-0" sm="6">
              <v-select :items="listaItensSelect"
                v-model="obj.pendingOperationTypeId" 
                label="Tipo" 
                @input="$v.obj.pendingOperationTypeId.$touch()"
                @blur="$v.obj.pendingOperationTypeId.$touch()" 
                :error-messages="pendingOperationTypeIdErros"
              />
            </v-col> -->
            <v-col cols="12" class="modal-cadastro-form-col-input pt-0" sm="6">
                <!-- :error-messages="valueErrros"  -->
              <v-currency-field 
                @input="$v.obj.value.$touch()" @blur="$v.obj.value.$touch()"
                v-model="obj.value" 
                :error-messages="valueErrors"
                label="Valor" 
                prefix="R$"/>
            </v-col>
            
  


          </v-row>
        </v-card-text>
        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn v-if="permiteAcao($route, 'add')" :color="variables.colorPrimary" :loading="loading" @click.prevent="salvarDados" class="br-btn">Enviar</v-btn>
        </v-card-actions>
        
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import { required, minValue } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { clone, filter, size } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import modalValidate from './modalValidate'
import storage from '@/core/service/localStorage'
import { mask } from 'vue-the-mask'
export default {
  name: 'ModalSmsMensagem',
  directives: { mask },
  components:{
    autoCompleteVendedor: () => import('./autoCompleteEntities'),
    autoCompleteEntities: () => import('@/views/components/autoCompleteEntities'),
    // inputDate: () => import('./inputDate')
    // dateTimerPicker: () => import('@/views/components/dateTimePicker')
  },
  data: () => ({
    loading: false,
    menuDateInicial: false,
    dtInicial: '',
    activeModal: false,
    input: '',
    file: null,
    promotor: {},
  }),
  computed: {
    ...modalValidate,
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('preCaixa', ['pList', 'filtro', 'obj', 'listaEntries', 'clearItens', 'listaEntitiesTypes']),
    ...mapGetters('tiposOperacoes', ['listaItensSelect']),
    ...mapGetters('auth', ['user']),
    variables: () => variables,
    isEdit () {
      return this.obj.id ? !!1 : !!0
    },

    isCashInAllEntities () {
      if (!this.user.jsonFlags) {
        return false
      }
      return this.user.jsonFlags.cashInAllEntities ? true : false
    }
  },
  mounted () {
    const vm = this
    Events.$on('modalCadastro::precaixa', v => {
      if (v.id) {
        vm.editarItem(v)
        this.promotor = v.promotor
      } else {
        vm.getLista({_filter: { 'entityTypeId_In': '4' }, type: 'v', origin: 'cachIn' })
        vm.getLista({_filter: { 'entityTypeId_In': '3' }, type: 'p', origin: 'cachIn' })
        vm.clear()
        vm.clearAll()
      }
      vm.activeModal = true
    })
    // this.entryTypes()
    this.getItens()
  },
  watch: {
    'value' (val) {
      this.entity = val
    },
    'obj.params.entity' (v) { // seleciona o promotor 
      const vm = this
      if (!this.user.jsonFlags || !this.user.jsonFlags.cashInAllEntities || this.isEdit) {
        if (v && v.value) {
          vm.filtroPromotor({ _filter: { 'id_=': v.parent}, origin: 'cachIn'  }).then(res => {
            vm.promotor = {text: res.resultMap[0].name, value: res.resultMap[0].id }
            vm.input = res.resultMap[0].name
          })

          vm.getLista({ _filter: { 'id_=': v.value}, type: 'v', origin: 'cachIn'  })
        } else {
          vm.promotors = {tex: '', value: ''}
          vm.input = ''
        }
      }
    }
  },
  methods: {
    ...mapActions('preCaixa', ['filtroPromotor', 'getLista', 'cadastrarItem', 'entryTypes', 'clearAll', 'editarItem', 'editarPreCaixa']),
    ...mapActions('tiposOperacoes', ['getItens']),
    setLabel (v) {
      let typos = this.listaEntitiesTypes
      if (size(typos)) {
        const name = filter(typos, it => it.id === parseInt(v))[0]
        return String(name.name).charAt(0) + String(name.name).substr(1).toLowerCase()
      }
    },
    openModal () { this.activeModal = true },
    salvarDados () {
      this.obj.pendingOperationTypeId = 'PC'
      const user = storage.get('auth-token')
      this.loading = true
      if (this.$v.obj.$invalid) {
        this.loading = false
        this.$v.obj.$touch()
        return false
      }
      

      let args = clone(this.obj)

      args.params.credenciador = {
        entityId: this.promotor.value
      }

      args.entityId = args.params.entity.value
      args.params.user = user.id
      args.value = Number(args.value).toFixed(2)
      delete args.p
      delete args.v
      this.request(args)     
    },

    request (args) {
      if (args.id) { // editar pre-caixa
        this.editarPreCaixa(args).then(() => {
          this.activeModal = false
          Events.$emit('Precaixa::reoladPage')
          successSnackbar('Operação realizada com sucesso!')    
        })
        .finally(() => { 
          this.loading = false
        })

      } else {
        delete args.id
        // faz o cadastro
        this.cadastrarItem(args).then(()=> {
          this.activeModal = false
          successSnackbar('Operação realizada com sucesso!')    
          Events.$emit('Precaixa::reoladPage')
        }).catch(e => errorSnackbar(e.error))
          .finally(() => { 
            this.loading = false
          })
      }
    },
    closeModal () {
      this.activeModal = false
      this.clear()
      this.clearAll()
      Events.$emit('clear::all')
    },
    clear () {
      this.input = ''
      this.$v.obj.$reset()
    }
  },
  validations () {
    return {
      obj: {
        value: {
          required,
          minValue: minValue(0.01)
        },
        pendingOperationTypeId: { required },
        params: {
          entity: {
            required
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}
</style>
